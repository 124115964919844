import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdxadLabelTabIconStatus, Dict, Dicts, LazyLoadDict } from '../../../../core/interface';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { ExchangeService } from '../../exchange.service';
import { MessageService } from '../../../../core/services/message.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadValidators } from '../../../../core/validators';
import { filter } from 'rxjs/operators';
import { getControlChanges } from '../../../../core/helpers/forms';
import { DspService } from '../../../../core/services/dsp.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-ssp-form',
  templateUrl: './ssp-form.component.html',
  styleUrls: ['./ssp-form.component.scss']
})
export class SspFormComponent implements OnInit {
  form: FormGroup;
  isLoading = false;
  isLoadingSubmit = false;
  isNewSsp = true;
  isShowPassback = false;

  dicts = {
    costModel: {
      data: [
        { id: 'cpc', value: 'cpc' },
        { id: 'cpm', value: 'cpm' }
      ]
    },
    advType: {
      data: [
        { id: 'pops', value: 'Popunders' },
        { id: 'push', value: 'Push' }
      ]
    },
    responseType: {
      data: [
        { id: 'plain', value: 'plain' },
        { id: 'exads', value: 'exads' }
      ]
    }
  } as Dicts;

  private cursorPosition = 0;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private alerts: AdxadAlerts,
    public config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private exchangeService: ExchangeService,
    private dspService: DspService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.isNewSsp = false;
    }

    this.createForm();
    this.getChanges();

    if (this.isNewSsp) {
      this.loadData();
    } else {
      this.loadSsp(this.config.data.id);
    }
  }

  get revShares(): FormArray {
    return this.form.get('geoRevshare') as FormArray;
  }

  /**
   * Create ssp form
   */
  createForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3), AdxadValidators.en]],
      extId: ['', [Validators.required]],
      accessToken: ['', [Validators.required]],
      segment: ['', [Validators.required, AdxadValidators.en, AdxadValidators.whiteSpacer]],
      responseTemplate: ['', [Validators.required]],
      responseType: ['plain', [AdxadValidators.en, AdxadValidators.whiteSpacer]],
      costModel: ['cpc'],
      advType: ['pops'],
      isOpenRtb: [false],
      isExoclick: [false],
      testMode: [true],
      isShortLink: [false],
      filters: this.fb.group({
        geoFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        dspFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        siteIdFilter: this.fb.group({
          mode: [false],
          value: [[]]
        })
      }),
      passbackUrl: ['', [AdxadValidators.clickUrl]],
      geoRevshare: this.fb.array([]),
      commission: this.fb.control(50, [Validators.required, Validators.min(0), Validators.max(100)])
    });

    if (!this.isNewSsp) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }
  }

  /**
   * @return tab valid status
   */
  getTabStatus(): AdxadLabelTabIconStatus {
    return !this.form.valid ? 'invalid' : 'success';
  }

  /**
   * Load ssp form dicts
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.dspService
      .getExchangeResponseTemplatePlaceholders(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: LazyLoadDict) => {
          this.dicts.responseTemplate = result;
          this.isLoading = false;

          if (this.isNewSsp) {
            this.generateSecret();
          }

          this.form.markAllAsTouched();
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Load dsp data
   *
   * @param id - ssp id
   */
  loadSsp(id: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.exchangeService
      .getSspForm({ id: id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.loadData();

          if (result.status === 'OK') {
            if (result.data.geoRevshare && result.data.geoRevshare.length > 0) {
              result.data.geoRevshare.forEach(control => {
                this.revShares.push(
                  this.fb.group({
                    geo: [control.geo, [Validators.required]],
                    revenue: [control.revenue, [Validators.min(0), Validators.max(100)]]
                  })
                );
              });
            }

            if (result.data.passbackUrl) {
              this.isShowPassback = true;
            }
            this.form.patchValue(result.data);
            this.form.updateValueAndValidity();
          }
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Subscribe form controls
   */
  getChanges(): void {
    const nameControl = this.form.get('name');

    getControlChanges(this.form, 'name')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => nameControl.dirty || nameControl.touched)
      )
      .subscribe({
        next: value => {
          const val = value.toLowerCase().replace(/\s+/g, '-');
          if (this.isNewSsp) {
            this.form.get('extId').patchValue(val);
          }
        }
      });

    getControlChanges(this.form, 'advType')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          const responseTemplateControl = this.form.get('responseTemplate') as FormControl;
          const pushConfig =
            '[{\n' +
            '   "id": "{{bidID}}",\n' +
            '   "text": "{{description}}",\n' +
            '   "title": "{{title}}",\n' +
            '   "cpc": {{price}},\n' +
            '   "icon_url": "{{iconURL}}",\n' +
            '   "image_url": "{{imageURL}}",\n' +
            '   "click_url": "{{clickURL}}"\n' +
            '}]';

          if (value === 'push' && this.isNewSsp) {
            responseTemplateControl.patchValue(pushConfig, { emitEvent: false });
          }
        }
      });

    getControlChanges(this.form, 'isOpenRtb')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          const responseTemplateControl = this.form.get('responseTemplate') as FormControl;
          const responseTypeControl = this.form.get('responseType') as FormControl;
          const isExoclickControl = this.form.get('isExoclick') as FormControl;

          if (!value) {
            responseTypeControl.setValue('');
            responseTemplateControl.setValidators([Validators.required]);
          } else {
            responseTemplateControl.setValue('');
            responseTypeControl.setValue('plain');
            responseTemplateControl.clearValidators();

            if (isExoclickControl.value === true) {
              isExoclickControl.setValue(false);
              isExoclickControl.updateValueAndValidity();
            }
          }

          responseTemplateControl.updateValueAndValidity();
          responseTypeControl.updateValueAndValidity();
        }
      });

    getControlChanges(this.form, 'isExoclick')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          const isOpenRtbControl = this.form.get('isOpenRtb') as FormControl;
          if (value && isOpenRtbControl.value === true) {
            isOpenRtbControl.setValue(false);
            isOpenRtbControl.updateValueAndValidity();
          }
        }
      });
  }

  /**
   * Set cursor position on blur field
   *
   * @param {FocusEvent} e
   */
  setCursorPosition(e: FocusEvent): void {
    this.cursorPosition = e.target['selectionStart'];
  }

  togglePassbackUrl(status: boolean): void {
    if (!status) {
      this.form.get('passbackUrl').setValue('');
      this.form.get('passbackUrl').updateValueAndValidity();
    }
    this.isShowPassback = status;
  }

  /**
   * Add placeholders in responseTemplate
   * or replace all same holders
   *
   * @param {Dict} placeholder
   */
  toggleUrlPlaceholders(placeholder: Dict): void {
    const control = this.form.get('responseTemplate');
    let code = control.value;

    if (code.indexOf(placeholder.id) !== -1) {
      const re = new RegExp(placeholder.id, 'g');
      code = code.replace(re, '');
    } else {
      code = code.slice(0, this.cursorPosition) + placeholder.id + code.slice(this.cursorPosition);
    }
    control.setValue(code);
  }

  /**
   * Generate secret token if isOpenRtb = false;
   */
  generateSecret(): void {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let newSecret = '';

    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      newSecret += chars[randomIndex];
    }

    this.form.get('accessToken').patchValue(newSecret);
    this.form.get('accessToken').updateValueAndValidity();
  }

  /**
   * Create/edit dsp
   */
  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;
    const data = this.form.value;

    const method = this.isNewSsp ? this.exchangeService.addSsp(data) : this.exchangeService.editSsp(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        this.isLoadingSubmit = false;
        if (response.status === 'OK') {
          const message = 'SSP successfully ' + (this.isNewSsp ? 'created' : 'changed');
          this.messageService.add('reload-exchange-ssps-grid', { submit: true });
          this.alerts.success(message, 3000);
          this.close(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
