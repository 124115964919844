<adxad-loader
  *ngIf="isLoading"
  [diameter]="40"
  adxadModalLoader
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{isNewSsp ? 'Add new SSP' : 'Edit ' + this.config.data.name}}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-tabs>
      <!--    General   -->
      <adxad-tab>
        <adxad-tab-label>
          General
          <adxad-tab-label-status-icon [status]="getTabStatus()" />
        </adxad-tab-label>
        <adxad-tab-body>
          <div class="row">
            <adxad-form-field class="field-group col-4">
              <adxad-input
                [autofocus]="true"
                formControlName="name"
                label="Name"
                placeholder="Enter SSP name"
              />
              <app-show-errors [control]="form.get('name')" />
            </adxad-form-field>

            <adxad-form-field class="field-group col-4">
              <adxad-input
                formControlName="extId"
                label="External ID"
                placeholder="Enter SSP external id"
              />
              <app-show-errors [control]="form.get('extId')" />
            </adxad-form-field>

            <adxad-form-field class="field-group col-4">
              <adxad-input
                [suffix]="true"
                formControlName="accessToken"
                label="Access token"
                placeholder="Click to icon for generate access token"
              >
                <span
                  (click)="generateSecret()"
                  adxadTooltip="Generate access token"
                  class="material-icons adxad-suffix --regenerate-access-token"
                >
                  refresh
                </span>
              </adxad-input>
            </adxad-form-field>
          </div>

          <div class="row">
            <adxad-form-field class="field-group col-4">
              <adxad-input
                formControlName="responseType"
                label="Response type"
                *ngIf="!form.get('isOpenRtb').value"
              />

              <adxad-select
                *ngIf="form.get('isOpenRtb').value"
                [options]="dicts.responseType.data"
                formControlName="responseType"
                label="Response type"
                placeholder="Select response type"
              />

              <app-show-errors [control]="form.get('responseType')" />
            </adxad-form-field>

            <adxad-form-field class="field-group col-4">
              <adxad-select
                [options]="dicts.costModel.data"
                formControlName="costModel"
                label="Cost model"
                placeholder="Select cost model"
              />
              <app-show-errors [control]="form.get('costModel')" />
            </adxad-form-field>

            <adxad-form-field class="field-group col-4">
              <adxad-select
                [options]="dicts.advType.data"
                formControlName="advType"
                label="Advert type"
                placeholder="Select advert type"
              />
              <app-show-errors [control]="form.get('advType')" />
            </adxad-form-field>
          </div>

          <div class="row">
            <adxad-form-field class="field-group col-4">
              <adxad-number-input
                formControlName="commission"
                label="Default revshare (%)"
                step="1"
              />

              <app-show-errors [control]="form.get('commission')" />
            </adxad-form-field>

            <adxad-form-field class="field-group col-4">
              <adxad-input
                formControlName="segment"
                label="Segment"
              />
              <app-show-errors [control]="form.get('segment')" />
            </adxad-form-field>
          </div>

          <div class="row">
            <div class="col-12 --checkbox-group">
              <adxad-checkbox formControlName="isOpenRtb"> Open RTB </adxad-checkbox>
              <adxad-checkbox formControlName="isExoclick"> Is Exoclick? </adxad-checkbox>
              <adxad-checkbox formControlName="testMode"> Test mode </adxad-checkbox>
              <adxad-checkbox
                (changeChecked)="togglePassbackUrl($event)"
                [checked]="!!form.get('passbackUrl').value"
              >
                Passback URL
              </adxad-checkbox>
              <adxad-checkbox formControlName="isShortLink"> Short link </adxad-checkbox>
            </div>
          </div>

          <div
            class="row"
            *ngIf="isShowPassback"
          >
            <adxad-form-field class="field-group col-12">
              <adxad-input
                formControlName="passbackUrl"
                label="Passback URL"
                placeholder="Enter passback URL"
              />
              <app-show-errors [control]="form.get('passbackUrl')" />
            </adxad-form-field>
          </div>

          <div
            *ngIf="!form.get('isOpenRtb').value"
            class="row"
          >
            <adxad-form-field class="field-group col-12">
              <adxad-textarea
                (blurEvent)="setCursorPosition($event)"
                [fixWidth]="true"
                [height]="'100'"
                formControlName="responseTemplate"
                label="Response template"
                placeholder="Enter response template"
              ></adxad-textarea>
              <app-show-errors [control]="form.get('responseTemplate')" />
            </adxad-form-field>

            <adxad-holders
              (changeHolder)="toggleUrlPlaceholders($event)"
              [holders]="dicts.responseTemplate.data"
              [text]="form.get('responseTemplate').value"
              class="col-12"
              label="Response template placeholders"
            ></adxad-holders>
          </div>
        </adxad-tab-body>
      </adxad-tab>
      <!--    End general   -->

      <!--    Filters   -->
      <adxad-tab>
        <adxad-tab-label> Filters </adxad-tab-label>

        <adxad-tab-body>
          <adxad-exchange-filter [group]="$any(this.form.get('filters'))" />
        </adxad-tab-body>
      </adxad-tab>
      <!--    End filters   -->

      <!--    Geo revshare   -->
      <adxad-tab>
        <adxad-tab-label> Geo revshare </adxad-tab-label>

        <adxad-tab-body>
          <adxad-geo-revshare [revShares]="revShares" />
        </adxad-tab-body>
      </adxad-tab>
      <!--    End geo revshare   -->
    </adxad-tabs>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        (click)="close()"
        adxadStrokedButton
        class="w100"
      >
        {{ 'Cancel' | uppercase }}
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        (click)="submit()"
        [disabled]="!form.valid || isLoadingSubmit"
        [isLoading]="isLoadingSubmit"
      >
        {{ (isNewSsp ? 'Create' : 'Save') | uppercase }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
